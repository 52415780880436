import { ChainId } from "@wagyu-swap/sdk";

import { serializeTokens } from "./tokens";
import { SerializedFarmConfig } from "./types";
import addresses from "./addresses.json";

const serializedTokens = serializeTokens();

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
const { MAINNET } = ChainId;

export const MATIC_ZOLA_LP_PID = chainId === MAINNET ? 2 : 1;
export const MATIC_USDT_LP_PID = chainId === MAINNET ? 1 : 3;

const farms: SerializedFarmConfig[] =
  chainId === MAINNET
    ? [
        {
          pid: 0,
          lpSymbol: "ZOLA LP",
          lpAddresses: {
            80002: "",
            137: "0xD2cDb1BBCacAddAECfaFd4E4182CCE96eB34f8C8",
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.cake,
        },
        {
          pid: 1,
          lpSymbol: "MATIC_USDT LP",
          lpAddresses: {
            80002: "",
            137: "0x09c807B96B97a76E8E953e5b8E07fCE9ef4Dad45",
          },
          token: serializedTokens.usdt,
          quoteToken: serializedTokens.wmatic,
        },
        {
          pid: 2,
          lpSymbol: "MATIC_ZOLA LP",
          lpAddresses: {
            80002: "",
            137: "0xb4055efcE1e7Bd63daF502A9DCd77A37A31d3A2E",
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.wmatic,
        },
        {
          pid: 3,
          lpSymbol: "ZOLA_QUICK_LP",
          lpAddresses: {
            80002: "",
            137: "0x512fa27ba0610114d59A8556aE69963fF1CfB3bc",
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.wmatic,
        },
        {
          pid: 5,
          lpSymbol: "dQUICK_MATIC LP",
          lpAddresses: {
            80002: "",
            137: "0xb46dfe0ed0896d1c7a1e3dEb07cf4f38235955D2",
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.wmatic,
        },
        {
          pid: 6,
          lpSymbol: "SHIB_MATIC_LP",
          lpAddresses: {
            80002: "",
            137: "0xD5e177Dea5540CB4F733FC95353F3a9739e7961F",
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.wmatic,
        },
      ]
    : [
        /**
         * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
         */
        {
          pid: 0,
          lpSymbol: "ZOLA",
          lpAddresses: {
            80002: "0xb830d8213e2c3dD621037703D05A5e594387B6Be",
            137: "0x1920AFDC480f51F5d01306B72184655Cd5A3bE69",
          },
          token: serializedTokens.syrup,
          quoteToken: serializedTokens.wmatic,
        },
        {
          pid: 1,
          lpSymbol: "MATIC_SKILYNE LP",
          lpAddresses: {
            80002: "0xC056BBe888547986Ad2bb0Ff7F9fA484A0c27743",
            137: "0xC056BBe888547986Ad2bb0Ff7F9fA484A0c27743",
          },
          token: serializedTokens.skilyne,
          quoteToken: serializedTokens.wmatic,
        },
        {
          pid: 2,
          lpSymbol: "MATIC_CRYPTOCASTER LP",
          lpAddresses: {
            80002: "0x40d4fC460eF4C76a20CE1BaEAEB6b921050f483f",
            137: "0x40d4fC460eF4C76a20CE1BaEAEB6b921050f483f",
          },
          token: serializedTokens.cryptocaster,
          quoteToken: serializedTokens.wmatic,
        },
      ];

export default farms;
