import { ChainId } from "@wagyu-swap/sdk";
import { serializeTokens } from "./tokens";
import { SerializedPoolConfig, PoolCategory } from "./types";

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
const { MAINNET } = ChainId;

const serializedTokens = serializeTokens();

const pools: SerializedPoolConfig[] =
  chainId === MAINNET
    ? [
        {
          sousId: 0,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "",
            137: "0xE675c0dd5add1E256070AD69da2E04c73Ea7dE71",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "1.50",
          sortOrder: 1,
          isFinished: false,
        },
        {
          sousId: 1,
          stakingToken: serializedTokens.wmatic,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "",
            137: "0xA14b9eFc33bf2026eb83824080e181Df7b97912B",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "3.98",
          sortOrder: 2,
          isFinished: false,
        },
        {
          sousId: 2,
          stakingToken: serializedTokens.shib,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: '',
            137: '0x863194A599257A74dfea442f38F8AB2E34939c46',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.35',
          sortOrder: 4,
          isFinished: false,
        },
        {
          sousId: 3,
          stakingToken: serializedTokens.quick,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: '',
            137: '0xf8a757B28958B2a7ae2acEd47d7D604acbFAa934',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.35',
          sortOrder: 5,
          isFinished: false,
        },
        // {
        //   sousId: 4,
        //   stakingToken: serializedTokens.dai,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     80002: '',
        //     137: ' 0x852bAb9C4767e3E008dc5D069C246ED8CC658e5e',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.35',
        //   sortOrder: 5,
        //   isFinished: false,
        // },
        {
          sousId: 5,
          stakingToken: serializedTokens.avax,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "",
            137: "0x2c83126FE048BC6450025e932cF9293aBe1DF208",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "2.61",
          sortOrder: 3,
          isFinished: false,
        },
        {
          sousId: 6,
          stakingToken: serializedTokens.uni,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: '',
            137: '0xB9fE6Ff8D3675Fe83AEF5297deeAB76B013388f7',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.35',
          sortOrder: 5,
          isFinished: false,
        },
        // {
        //   sousId: 7,
        //   stakingToken: serializedTokens.uni,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     80002: '',
        //     137: '0x1E2B9C5d4533F8E52e6B0ceA08EEA27DDDa52CB9',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.35',
        //   sortOrder: 5,
        //   isFinished: false,
        // },
        // {
        //   sousId: 8,
        //   stakingToken: serializedTokens.wbtc,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     80002: '',
        //     137: '0x8C1dC3E4De49cE7ad2bCAFE9D36405112351d3f7',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.35',
        //   sortOrder: 5,
        //   isFinished: false,
        // },
      ]
    : [
        {
          sousId: 0,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "",
            137: "0xa7e8280b8CE4f87dFeFc3d1F2254B5CCD971E852",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "1.3",
          sortOrder: 1,
          isFinished: false,
        },
        {
          sousId: 1,
          stakingToken: serializedTokens.te6,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "0x77e631209Cc3151c2835fF2B7585d8f0Db781aff",
            137: "0x77e631209Cc3151c2835fF2B7585d8f0Db781aff",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "1",
          sortOrder: 1,
          isFinished: false,
        },
        {
          sousId: 2,
          stakingToken: serializedTokens.te9,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "0xf4BB219600ad1680cA381331bF9ADd4422844BcC",
            137: "0xf4BB219600ad1680cA381331bF9ADd4422844BcC",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "1",
          sortOrder: 1,
          isFinished: false,
        },
        {
          sousId: 3,
          stakingToken: serializedTokens.te12,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "0x98a6816Cd29F063Fe3c1ECA3492Bb2D5E36f467a",
            137: "0x98a6816Cd29F063Fe3c1ECA3492Bb2D5E36f467a",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "1",
          sortOrder: 1,
          isFinished: false,
        },
        {
          sousId: 4,
          stakingToken: serializedTokens.te18,
          earningToken: serializedTokens.cake,
          contractAddress: {
            80002: "0xccE4829D6D2c04A006F774968bc41d1d2fe714cB",
            137: "0xccE4829D6D2c04A006F774968bc41d1d2fe714cB",
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: "1",
          sortOrder: 1,
          isFinished: false,
        },
      ];

export default pools;
