import { ChainId, Token } from "@wagyu-swap/sdk";
import { serializeToken } from "state/user/hooks/helpers";
import { SerializedToken } from "./types";
import addresses from "./addresses.json";

const { MAINNET, TESTNET } = ChainId;

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

interface TokenList {
  [symbol: string]: Token;
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken;
}

export const mainnetTokens = {
  // matic here points to the wmatic contract. Wherever the currency MATIC is required, conditional checks for the symbol 'MATIC' can be used
  wmatic: new Token(
    MAINNET,
    addresses[137].WMATIC,
    18,
    "WMATIC",
    "Wrapped MATIC",
    "https://zolaswap.finance/"
  ),
  weth: new Token(
    MAINNET,
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    18,
    "WETH",
    "Wrapped ETH",
    "https://weth.io/"
  ),
  wittyou: new Token(
    MAINNET,
    "0xBe7BA3504072244d882360cCBDDc8e9f09400C8C",
    18,
    "WITTYOU",
    "Wittyou",
    "https://zolaswap.finance/"
  ),
  cryptocaster: new Token(
    MAINNET,
    "0x36b2b3d2839F86a96d6D78c00fBb448d9B4d21B0",
    18,
    "CRYPTOCASTER",
    "Cryptocaster",
    "https://zolaswap.finance/"
  ),
  squiddx: new Token(
    MAINNET,
    "0x584F0D3616Ec9Ea73f9DF892E6B5C342BA128eFc",
    18,
    "SQUIDDX",
    "Squiddy",
    "https://zolaswap.finance/"
  ),
  speedbasket: new Token(
    MAINNET,
    "0x1Fdbd5Ac1fCAFd3Ee024652F0a6aEeDAa4aab334",
    18,
    "SPEEDBASKET",
    "Speedbasket",
    "https://zolaswap.finance/"
  ),
  matic: new Token(
    MAINNET,
    addresses[137].WMATIC,
    18,
    "MATIC",
    "MATIC",
    "https://zolaswap.finance/"
  ),
  aave: new Token(
    MAINNET,
    "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    18,
    "AAVE",
    "AAVE Token",
    "https://aave.finance/"
  ),
  shib: new Token(
    MAINNET,
    "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec",
    18,
    "SHIB",
    "Shiba Inu",
    "https://shibainu.finance/"
  ),
  bnb: new Token(
    MAINNET,
    "0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3",
    18,
    "BNB",
    "BNB",
    "https://binance.com"
  ),
  elon: new Token(
    MAINNET,
    "0xE0339c80fFDE91F3e20494Df88d4206D86024cdF",
    18,
    "ELON",
    "Elon",
    "https://zolaswap.finance"
  ),
  cake: new Token(
    MAINNET,
    "0xD2cDb1BBCacAddAECfaFd4E4182CCE96eB34f8C8",
    18,
    "ZOLA",
    "ZOLA Token",
    "https://zolaswap.finance/"
  ),
  zolapf: new Token(
    MAINNET,
    "0x2c73926A7D82543163C2446bA4a82eA2C29fC2D7",
    18,
    "ZOLApf",
    "ZOLApf Token",
    "https://zolaswap.finance/"
  ),
  usdc: new Token(
    MAINNET,
    "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    6,
    "USDC",
    "USDC Token",
    "https://centre.io"
  ),
  quick: new Token(
    MAINNET,
    "0xB5C064F955D8e7F38fE0460C556a72987494eE17",
    18,
    "QUICK(OLD)",
    "https://quickswap.exchange/"
  ),
  dquick: new Token(
    MAINNET,
    "0x958d208Cdf087843e9AD98d23823d32E17d723A1",
    18,
    "dQUICK(NEW)",
    "https://quickswap.exchange/"
  ),
  banana: new Token(
    MAINNET,
    "0x5d47bAbA0d66083C52009271faF3F50DCc01023C",
    18,
    "BANANA",
    "https://apeswap.finance/"
  ),
  usdt: new Token(
    MAINNET,
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    6,
    "USDT",
    "https://mapper.matic.today/"
  ),
  kom: new Token(
    MAINNET,
    "0xC004e2318722EA2b15499D6375905d75Ee5390B8",
    8,
    "KOM",
    "https://www.kommunitas.net/"
  ),
  luna: new Token(
    MAINNET,
    "0x24834BBEc7E39ef42f4a75EAF8E5B6486d3F0e57",
    18,
    "LUNA",
    "https://zolaswap.finance/"
  ),
  wbtc: new Token(
    MAINNET,
    "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    8,
    "WBTC",
    "https://zolaswap.finance/"
  ),
  mana: new Token(
    MAINNET,
    "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4",
    18,
    "MANA",
    "https://decentraland.org/"
  ),
  dai: new Token(
    MAINNET,
    "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    18,
    "DAI",
    "https://makerdao.com/"
  ),
  grt: new Token(
    MAINNET,
    "0x5fe2B58c013d7601147DcdD68C143A77499f5531",
    18,
    "GRT",
    "https://thegraph.com/"
  ),
  syrup: new Token(
    MAINNET,
    addresses[137].ZOLAStake,
    18,
    "Zhuzi (Syrup)",
    "Zhuzi (Syrup) Token",
    "https://zolaswap.finance/"
  ),
  skilyne: new Token(
    MAINNET,
    "0xd0AE67Ddf94AF33189B90cAD4238023dCD2d2dD6",
    18,
    "SKILYNE",
    "SkiLyne",
    "https://zolaswap.finance/"
  ),
  gorydz: new Token(
    MAINNET,
    "0xd4374E059648Df86cA9465484cFBa4CD4540E146",
    18,
    "GORYDZ",
    "Gorydz",
    "https://zolaswap.finance/"
  ),
  amibc: new Token(
    MAINNET,
    "0xD122FACf60cBEaF0C7c17962de14d481F7bB2DD8",
    18,
    "AMIBC",
    "AMIBC",
    "https://zolaswap.finance/"
  ),
  zroar: new Token(
    MAINNET,
    "0x8FBA2D53ec41a32e8e36A105651a2e6b03Fa14D0",
    18,
    "ZROAR",
    "ZROAR",
    "https://zolaswap.finance/"
  ),
  khaunda: new Token(
    MAINNET,
    "0xE0A9c1f476eE34f0c56Ba2Ebb648261BC3C00d57",
    18,
    "KHAUNDA",
    "KHAUNDA",
    "https://zolaswap.finance/"
  ),
  alzie: new Token(
    MAINNET,
    "0xd715893B90C98E20B7aF8eDEB8c9c008ea9c8970",
    18,
    "ALZIE",
    "ALZIE",
    "https://zolaswap.finance/"
  ),
  avax: new Token(
    MAINNET,
    "0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b",
    18,
    "AVAX",
    "AVAX",
    "https://www.avax.network/"
  ),
  uni: new Token(
    MAINNET,
    "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    18,
    "UNI",
    "UNI",
    "https://uniswap.org/"
  ),
};

export const testnetTokens = {
  wmatic: new Token(
    TESTNET,
    addresses[80002].WMATIC,
    18,
    "WMATIC",
    "Wrapped MATIC",
    "https://zolaswap.finance/"
  ),
  cake: new Token(
    TESTNET,
    addresses[80002].ZolaToken,
    18,
    "ZOLA",
    "ZOLA Token",
    "https://zolaswap.finance/"
  ),
  usdc: new Token(
    TESTNET,
    addresses[80002].USDCToken,
    18,
    "USDC",
    "USDC Token",
    "https://zolaswap.finance/"
  ),
  usdt: new Token(
    TESTNET,
    addresses[80002].USDTToken,
    18,
    "USDT",
    "USDT Token",
    "https://zolaswap.finance/"
  ),
  weth: new Token(
    TESTNET,
    addresses[80002].WETH,
    18,
    "WETH",
    "WETH Token",
    "https://zolaswap.finance/"
  ),
};

const tokens = (): TokenList => {
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (chainId === ChainId.TESTNET) {
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {})

    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] };
    }, {});
  }

  return mainnetTokens;
};

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens();
  const serializedTokens = Object.keys(unserializedTokens).reduce(
    (accum, key) => {
      return { ...accum, [key]: serializeToken(unserializedTokens[key]) };
    },
    {}
  );

  return serializedTokens;
};

export default tokens();
