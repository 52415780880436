import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

/**
 * A hook to fetch price of zola token from GeckoTerminal's API for a specific pool on Polygon.
 * @returns The price of zola token as a BigNumber or undefined if not fetched yet or in case of an error.
 */
const usePriceZola = (): BigNumber | undefined => {
  const [priceZola, setPriceZola] = useState<BigNumber | undefined>(undefined);

  useEffect(() => {
    const fetchZola = async () => {
      const url = "https://api.geckoterminal.com/api/v2/networks/polygon_pos/tokens/0xD2cDb1BBCacAddAECfaFd4E4182CCE96eB34f8C8";
      
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error fetching zola price: ${response.statusText}`);
        }
        const data = await response.json();
        // Assuming the response JSON structure contains a `fdv_usd` field at the top level for fully diluted valuation in USD.
        // Adjust the path based on the actual API response structure.
        const priceUsd = new BigNumber(data.data.attributes.price_usd);
        setPriceZola(priceUsd);
      } catch (error) {
        console.error("Failed to fetch zola price:", error);
        setPriceZola(undefined);
      }
    };

    fetchZola();
  }, []); // Empty dependency array means this effect runs once after the initial render.

  return priceZola;
};

export default usePriceZola;
