import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
 // const textColor = isDark ? "#FFFFFF" : "#000000";
  return (
    <img src="images/zolaswap-text.png" alt="Logo" {...props} />
  );
};

export default Icon
