import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

interface LogoProps extends SvgProps {
  isDark: boolean;
}

const Logo: React.FC<LogoProps> = (props) => {
  
  // const textColor = isDark ? "#FFFFFF" : "#000000";
  return (
    <img src="/images/zolaswap-text.png" alt="Logo" {...props} />
  );
};

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark);
