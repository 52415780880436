import addresses from "./addresses.json";

export default {
  masterChef: {
    80002: addresses[80002].ZOLAFarm,
    137: addresses[137].ZOLAFarm,
  },
  sousChef: {
    80002: addresses[80002].ZOLAStakingFactory,
    137: addresses[137].ZOLAStakingFactory,
  },
  lotteryV2: {
    80002: "0x11FbA0b1ED82824b2bccC99a3098e6328ba4C610",
    137: "0xE46248C30B883d54c59a4269bbad750B198C87ce",
  },
  multiCall: {
    137: addresses[137].Multicall2,
    80002: addresses[80002].Multicall2,
  },
  // not
  pancakeProfile: {
    80002: "",
    137: "0x9591Ee48575A84897Aed876e367b54e5cAb98703",
  },
  //
  pancakeRabbits: {
    137: "0xDf7952B35f24aCF7fC0487D01c8d10690a60DBa07",
    80002: "0x60935F36e4631F73f0f407e68642144e07aC7f5E",
  },
  //
  bunnyFactory: {
    137: "0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf",
    80002: "0x707CBF373175fdB601D34eeBF2Cf665d08f01148",
  },
  //
  claimRefund: {
    137: "0xE7e53A7e9E3Cf6b840f167eF69519175c4111e149",
    80002: "",
  },
  pointCenterIfo: {
    137: "",
    80002: "0xE7e53A7e9E3Cf6b840f167eF69519175c4111e149",
  },
  bunnySpecial: {
    137: "0xFee8A195570a18461146F401d6033f5ab3380849",
    80002: "0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C",
  },
  tradingCompetition: {
    137: "0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA",
    80002: "0xC787F45B833721ED3aC46E99b703B3E1E01abb111",
  },
  easterNft: {
    137: "0x23c41D28A239dDCAABd1bb1deF8d057189510066",
    80002: "0x24ec6962dbe874F6B67B5C5085710610667fA0854F",
  },
  cakeVault: {
    137: addresses[137].ZolaVault,
    80002: addresses[80002].ZolaVault,
  },
  predictions: {
    137: "0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA",
    80002: "",
  },
  chainlinkOracle: {
    137: "0xD276fCF34D54A926773c399eBAa772C12ec394aC",
    80002: "",
  },
  bunnySpecialCakeVault: {
    137: "0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4",
    80002: "",
  },
  bunnySpecialPrediction: {
    137: "0x342c99e9aC24157657095eC69CB04b73257e7A9C",
    80002: "",
  },
  bunnySpecialLottery: {
    137: "0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2",
    80002: "0x382cB4111110F398F0f152cae82821476AE51c9cF",
  },
  farmAuction: {
    137: "0xb92Ab7c1edcb273AbA24b06106cEb3681654805D2",
    80002: "0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe",
  },
};
