import { useCallback } from 'react';
import { ethers, Contract, PayableOverrides } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import getCurrentBaseFee from 'utils/getCurrentBaseFee'; // Ensure this utility correctly fetches the latest base fee

export function useCallWithGasPrice() {
  const { library } = useWeb3React();

  const callWithGasPrice = useCallback(async (
    contract: Contract,
    methodName: string,
    methodArgs: any[] = [],
    overrides: PayableOverrides = {}
  ): Promise<ethers.providers.TransactionResponse> => {
    // Fetch the current base fee from the network
    const currentBaseFee = await getCurrentBaseFee(library);
    const baseFeePerGas = ethers.BigNumber.from(currentBaseFee);
    const maxPriorityFeePerGas = ethers.utils.parseUnits('2', 'gwei'); // This can be adjusted or fetched dynamically

    // Calculate the max fee per gas to ensure the transaction goes through
    const maxFeePerGas = baseFeePerGas.add(maxPriorityFeePerGas.mul(2)); // Adjust the multiplier based on your needs

    const contractMethod = contract[methodName];
    const finalOverrides = {
      ...overrides,
      maxPriorityFeePerGas,
      maxFeePerGas,
    };

    const tx = await contractMethod(...methodArgs, finalOverrides);
    return tx;
  }, [library]);

  return { callWithGasPrice };
}
