import tokens from './tokens'
import { SerializedFarmConfig } from './types'

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absense of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  // {
  //   pid: null,
  //   lpSymbol: "ZOLA-WMATIC LP",
  //   lpAddresses: {
  //     80002: "",
  //     137: "0x09c807B96B97a76E8E953e5b8E07fCE9ef4Dad45",
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.wmatic,
  // },
]

export default priceHelperLps
