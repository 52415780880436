import React from 'react';
import styled from 'styled-components';
import { CogIcon } from '../../../components/Svg';
import IconButton from '../../../components/Button/IconButton';
import { MENU_ENTRY_HEIGHT } from '../config';
import { PanelProps, PushedProps } from '../types';
import CakePrice from './CakePrice';
// import ThemeSwitcher from './ThemeSwitcher';
// import SocialLinks from './SocialLinks';
// import LangSelector from './LangSelector';

interface Props extends PanelProps, PushedProps {}

const Container = styled.div`
  flex: none;
  padding: 8px 4px;
  background-color: ${({ theme }) => theme.nav.background};
  border-top: solid 2px rgba(133, 133, 133, 0.1);
`;

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
`;

const PanelFooter: React.FC<Props> = ({
  isPushed,
  pushNav,
  // toggleTheme,
  // isDark,
  // currentLang,
  // langs,
  // setLang,
}) => {
  if (!isPushed) {
    return (
      <Container>
        <IconButton variant="text" onClick={() => pushNav(true)}>
          <CogIcon />
        </IconButton>
      </Container>
    );
  }

  return (
    <Container>
      <SocialEntry>
        <CakePrice />
        {/* SocialLinks can be uncommented and used here if needed */}
        {/* <SocialLinks /> */}
      </SocialEntry>
      {/* SettingsEntry can be uncommented and used here if needed */}
      {/* <SettingsEntry>
        <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
        <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
      </SettingsEntry> */}
    </Container>
  );
};

export default PanelFooter;
