import { ethers } from 'ethers';

/**
 * Fetches the current base fee from the Ethereum network.
 * @param {ethers.providers.Provider} provider - An ethers provider instance to perform JSON RPC calls.
 * @return {Promise<ethers.BigNumber>} - The current base fee per gas as a BigNumber.
 */
async function getCurrentBaseFee(provider) {
    try {
        const latestBlock = await provider.getBlock('latest');
        // Ensure that baseFeePerGas exists (it should on networks supporting EIP-1559).
        if (!latestBlock || !latestBlock.baseFeePerGas) {
            throw new Error('Base fee per gas not available in the latest block data.');
        }
        return ethers.BigNumber.from(latestBlock.baseFeePerGas);
    } catch (error) {
        console.error('Failed to fetch base fee:', error);
        throw error; // Re-throw the error to handle it in the calling function.
    }
}

export default getCurrentBaseFee;
