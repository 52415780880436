import React from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import Text from '../../../components/Text/Text';
import Skeleton from '../../../components/Skeleton/Skeleton';
import usePriceZola from '../../../../../../hooks/usePriceZola';

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const ImgWrapper = styled.img`
  width: 32px;
`;

const CakePrice: React.FC = () => {
  const cakePriceUsd = usePriceZola();

  return cakePriceUsd ? (
    <PriceLink
      href="https://zolaswap.finance/swap?outputCurrency=0xD2cDb1BBCacAddAECfaFd4E4182CCE96eB34f8C8"
      target="_blank"
    >
      <ImgWrapper alt="logo" className="mr-24" src="/images/zolaswap.png" />
      {/* Format the price with 2 decimal places for display */}
      <Text color="textSubtle" bold>{`$${cakePriceUsd.toFixed(5)}`}</Text>
    </PriceLink>
  ) : (
    <Skeleton width={80} height={24} />
  );
};

export default React.memo(CakePrice);
